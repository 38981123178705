.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.box {
    border:1px #666 solid;
    height: 100%;
}

@media screen and (min-width: 0px) {
    .flex-row [class*='col'] {
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 768px) {
    .flex-row [class*='col'] {
        margin-bottom: 0;
    }
}