.header-l {
    background: #6B0D25;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #ffddbb;
    font-size: 2rem;
}

.main-l {
    font-size: 3rem;
    color: black;
}

.calendar-icon-l {
    background: #ffffff;
    text-align: center;
    height: 125px;
    width: 125px;
    border-radius: 12px 12px 10px 10px;
}

.header-m {
    background: #6B0D25;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #ffddbb;
    font-size: 1rem;
}

.main-m {
    font-size: 2rem;
    color: black;
}

.calendar-icon-m {
    background: #ffffff;
    text-align: center;
    height: 75px;
    width: 75px;
    border-radius: 8px 8px 6px 6px;
}