$max-image-height: 400px;
$max-copy-height: 500px;
$min-event-height: $max-image-height + $max-copy-height;
$dual-min-width: 768px;
$max-width: 1600px;
$arrow-width: 5%;
$event-width: 45%;
$label-radius: 3em;
$padding: 0.5em;
$radius: 0.35em;
$dot-radius: 5%;
$arrow-offset: 20px;
$fade-height: 80px;
$text-color: #313740;
$label-color: lighten(#2d384b, 15%);
$event-color: #f0f0f0;
$button-color: #5592cb;
$header-color: lighten(#2d384b, 30%);
$transparent-white: rgba(255, 255, 255, 0);

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.75em;
}

.rt-timeline-container {
  padding: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rt-timeline {
  max-width: $max-width;
  padding: 0em;
  list-style-type: none;
  background-repeat: repeat-y;
  background-position: 50% 0;
}

.rt-label-container {
  clear: both;
  //margin: 1em auto 1em auto;
  display: flex;
  justify-content: center;

  &:first-of-type {
    margin-top: 0 !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.rt-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  background: $label-color;
  font-size: 2em;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  width: $label-radius;
  height: $label-radius;
}

.rt-clear {
  clear: both;
}

.rt-backing {
  //border-radius: $radius;
  //background: $event-color;
  //color: $text-color;
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
}

.rt-event {
  position: relative;
  float: right;
  clear: right;
  width: $event-width;
  margin-bottom: 4em;
  margin-left: 0;
  margin-right: 0;
  list-style-type: none;
  display: block;
  min-height: $min-event-height;
  text-align: left;
  @media all and (max-width: $dual-min-width) {
    min-height: auto;
    width: 85%;    
    svg {
      display: none;
    }

    .rt-dot {
      display: none;
    }
  }

  .rt-dot {
    position: absolute;
    margin-top: $arrow-offset;
    left: -(11% + ($dot-radius * 0.5));
    right: auto;
    fill: currentcolor;
    color: $header-color;
    width: $dot-radius;
    z-index: 100;
  }

  .rt-svg-container {
    position: relative;

    svg {
      transform: scale(-1, 1);
    }

    .rt-arrow {
      z-index: 100;
      position: absolute;
      margin-top: $arrow-offset;
      left: -($arrow-width);
      right: auto;
      fill: currentcolor;
      width: $arrow-width + 0.25%;
      color: $header-color;
    }
  }

  &:nth-of-type(2n) {
    float: left;
    clear: left;

    svg {
      transform: scale(1, 1);
    }

    .rt-arrow {
      left: auto;
      right: -$arrow-width;
    }

    .rt-dot {
      left: auto;
      right: -(11% + ($dot-radius * 0.5));
    }
  }
}

li:nth-child(3) {
  margin-top: ($max-copy-height + $max-image-height) * 0.5;

  @media all and (max-width: $dual-min-width) {
    margin-top: 1em;
  }
}

.rt-title {
  margin: 4px;
  font-weight: bold;
}

.rt-date {
  margin: 4px;
  font-weight: normal;
}

.rt-header-container {
  color: white;
  margin-bottom: 1em;
  //padding: $padding * 0.5;
  //background-color: $header-color;
  border-radius: $radius $radius 0 0;
  overflow: hidden;
}

.rt-image-container {
  padding: 0;
  overflow: hidden;
}

.rt-image {
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: $max-image-height;
  object-fit: contain;
}

.rt-footer-container {
  padding: $padding;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.rt-btn {
  text-align: center;
  background-color: $text-color;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: $radius;
  padding: $padding;
  width: 100%;
  color: white;
  text-decoration: none;
}

.rt-text-container {
  margin-bottom: 1em;
  //padding: $padding;
  max-height: $max-copy-height;
  font-weight: lighter;
  overflow: hidden;
  text-overflow: ellipsis;
  content: '';
  position: relative;
  text-align: left;
}

.rt-text-container:before {
  //content: '';
  //font-weight: lighter;
  //width: 100%;
  //height: $fade-height;
  //position: absolute;
  //left: 0;
  //top: $max-copy-height - $fade-height;
  //background: transparent;
  //background: -webkit-linear-gradient($transparent-white, $event-color); /* For Safari 5.1 to 6.0 */
  //background: -o-linear-gradient($transparent-white, $event-color); /* For Opera 11.1 to 12.0 */
  //background: -moz-linear-gradient($transparent-white, $event-color); /* For Firefox 3.6 to 15 */
  //background: linear-gradient($transparent-white, $event-color); /* Standard syntax */
}

@media screen and (max-width: $dual-min-width) {
  .rt-event {
    width: 100%;
  }
}

@media screen and (min-width: $dual-min-width + 1) {
  .rt-timeline {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAtSURBVBhXY2RAA9fazP+D6NmvrYT7+/vfgQWBgAlKYwBkRSCAUyE6oLZCBgYAVv4JpfXqKygAAAAASUVORK5CYII=);
  }
}