.special-row {
    padding: 60px 0;
}

.golden-line-top {
    border-top: solid 1px #d68637;
}

.golden-line-bottom {
    border-bottom: solid 1px #d68637;
}

.title {
    padding-top: 150px;
}