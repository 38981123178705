.team-card {
    height: 100%;
    text-align: center;
    position: relative;
    background-color: unset;
    overflow-y: hidden;
    border: unset;
    cursor: pointer;
}

.team-card-background {
    background-color: #272727;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 3px;
}

.team-card-body {
    z-index: 2;
}

.team-card:hover {
    box-shadow: 0px 0px 0px 2px #d68637;
}

.team-card-image {
    margin-bottom: 10px;
}

.team-card-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    color: #d68637;
}

.team-card-button {
    //display: inline-block;
    z-index: 2;
    margin: 0 20px 20px 20px;
}

@media screen and (min-width: 0px) {
    .team-card-image {
        width: 13rem;
    }

    .team-card-background {
        top: 8.5rem;
    }
}

@media screen and (min-width: 576px) {
    .team-card-image {
        width: 13rem;
    }

    .team-card-background {
        top: 8.5rem;
    }
}

@media screen and (min-width: 768px) {
    .team-card-image {
        width: 7rem;
    }

    .team-card-background {
        top: 4.5rem;
    }
}

@media screen and (min-width: 992px) {
    .team-card-image {
        width: 10rem;
    }

    .team-card-background {
        top: 6rem;
    }
}

@media screen and (min-width: 1200px) {
    .team-card-image {
        width: 13rem;
    }

    .team-card-background {
        top: 7.5rem;
    }
}