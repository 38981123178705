#footer {
    background: rgb(94,12,40);
    background: linear-gradient(90deg, rgba(37,15,22,1) 0%, rgba(65,8,28,1) 20%, rgba(94,12,40,1) 50%,
    rgba(65,8,28,1) 80%, rgba(37,15,22,1) 100%);
    border-top: solid 1px #d68637;
    padding: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 12.5rem;
    font-size: 0.8rem;
}

#contact p {
    text-align: left;
    margin: 0;
}