.event-card {
    background: transparent;
    border: none;
}

.event-card .card-body {
    background: transparent;
}

.event-card .card-title {
    color: #ffddbb;
    font-size: 2rem;
}

.event-card .card-subtitle {
    color: #d68637 !important;
    font-size: 1.25rem;
}

.event-card .card-body .btn {
    background: transparent;
    border: #d68637 1px solid;
    color: #ffddbb;
    margin-top:  30px;
    width: 100%;
}