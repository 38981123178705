.App {
  text-align: center;
  color: #FFDDBD;
  font-size: 17px;
  position: relative;
  min-height: 100vh;
  background: #171717;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 1920px) {
  .content {
    margin: 0 200px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row {
  margin: 0;
}

.h1, h1 {
  font-weight: 800 !important;
  color: #FFA34C !important;
  text-shadow: 5px 5px 3px rgba(0, 0, 0, .3);
}

.h2, h2 {
  color: #FFDDBD;
  font-weight: bolder !important;
}

.h4, h4 {
  color: #d68637;
  font-weight: bolder !important;
}

.h3, h3 {
  color: #d68637;
  font-weight: bolder !important;
}

.header-page {
  font-family: Assistant, sans-serif;
  font-weight: 900;
  color: #d68637;
}

.title-image {
  max-width: 60%;
}

.btn-primary {
  --bs-btn-bg: #d68637 !important;
  --bs-btn-border-color: #150d04 !important;
  --bs-btn-hover-bg: #be7327 !important;
  --bs-btn-active-bg: #94591e !important;
  --bs-btn-hover-border-color: #40280c !important;
  --bs-btn-active-border-color: #40280c !important;
  --bs-btn-focus-shadow-rgb: 64,40,12 !important;
}

@media only screen and (min-width: 0px) {
  .h1, h1 {
    font-size: 40px !important;
  }

  .h2, h2 {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 576px) {
  .h1, h1 {
    font-size: 70px !important;
  }

  .h2, h2 {
    font-size: 40px !important;
  }
}
