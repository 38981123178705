@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;800&display=swap');

body {
  margin: 0;
  font-family: 'Assistant', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(90deg, rgba(37,15,22,1) 0%, rgba(65,8,28,1) 20%, rgba(94,12,40,1) 50%,
  rgba(65,8,28,1) 80%, rgba(37,15,22,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Assistant';
  src: local('Assistant'), url(./font/Assistant.ttf) format('truetype');
}

:root {
  --bs-body-font-weight: 200;

}

@custom-media --small-viewport (max-width: 576px);
@custom-media --medium-viewport (max-width: 768px);
@custom-media --large-viewport (max-width: 992px);
@custom-media --extra-large-viewport (max-width: 1200px);