.team-member-details-col {
    padding: 10px;
}

.team-member-details-box {
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    background-color: #272727;
    height: 100%;
}

.team-member-details-image {
    width: 15rem;
}

.team-member-details-box hr {
    margin-top: 8px;
    margin-bottom: 10px;
}

.team-member-details-box h5 {
    text-align: center;
}

@media screen and (min-width: 0px) {
    .team-member-details-title {
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .team-member-details-title {
        text-align: left;
    }
}