.division-line {
    border-right: solid 2px #E69137;
}

@media screen and (max-width: 768px) {
    .responsive-margin {
        margin-bottom: 2em;
    }
}

@media screen and (min-width: 992px) {
    .title-spacing {
        padding-top: 30px;
        padding-bottom: 300px;
    }
}