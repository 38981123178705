.customBtn {
    background-color: transparent;
    border: none;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex !important;
}

.customBtn:hover {
    background-color: transparent;
}

.customBtn:focus {
    background-color: transparent;
}

.icon {
    position: absolute;
}

.icon:hover {
    width:25px;
    height:25px;
    transition:width 0.1s ease 0s,height 0.1s ease 0s;
    transform-origin: center;
}