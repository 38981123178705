#main-nav {
    padding: 0;
    width: 100%;
    position: fixed;
    z-index: 10;
    font-weight: bold;
    font-size: large;
    background: rgba(65,8,28,1);
}


#main-nav-collapse {
}

.navbar-nav {
    height: 100%;
}

.navbar-collapse {
    flex-grow: 0;
}

.nav-link {
    margin-right: 20px;
    transition: color .15s ease-in-out, border-bottom-color .15s ease-in-out;
    display: flex;
    align-items: center;
}

.nav-link:hover {
    border-bottom-color: #FFDDBA;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #FFDDBA !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #d68637;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #FFDDBA;
}

.dropdown-item {
    color: #d68637;
}

.dropdown-item:hover {
    background: #41081b;
    color: #FFDDBA;
}

.dropdown-menu {
    background: #250f16;
    background: #250f16;
    border: unset;
}

.navbar-light .navbar-toggler {
    color: #d68637;
    border: unset;
}

.navbar-light .navbar-toggler-icon {
    filter: brightness(0) saturate(100%) invert(58%) sepia(15%) saturate(1872%) hue-rotate(349deg) brightness(150%) contrast(95%);

}

.navbar-toggler-icon {
    width: 2rem;
    height: 2rem;
}

@media (min-width: 992px) {
    .nav-link {
        border-bottom: solid 3px rgba(225, 221, 186, 0);
    }

    .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
        border-bottom: solid 3px #FFDDBA;
    }

    .is-scrolled-top-bg {
        background: linear-gradient(rgba(65,8,28,1), rgba(0,0,0,0));
        animation: nav-bg-out .5s ease;
        animation-fill-mode: forwards;
    }

    .is-not-scrolled-top-bg {
        animation: nav-bg-in .5s ease;
        animation-fill-mode: forwards;
    }

    .page-load-animation-delay {
        animation-delay: -.5s;
    }

    @keyframes nav-bg-in {
        0% {
            background: linear-gradient(rgba(65,8,28,1), rgba(0,0,0,0)), rgba(0,0,0,0);
        }
        100% {
            background: linear-gradient(rgba(65,8,28,1), rgba(0,0,0,0)), rgba(65,8,28,1);

        }
    }

    @keyframes nav-bg-out {
        0% {
            background: linear-gradient(rgba(65,8,28,1), rgba(0,0,0,0)), rgba(65,8,28,1);
        }
        100% {
            background: linear-gradient(rgba(65,8,28,1), rgba(0,0,0,0)), rgba(0,0,0,0);
        }
    }

    .is-scrolled-top-logo {
        animation: bg-scale-bigger .5s ease;
        animation-fill-mode: forwards;
    }

    .is-not-scrolled-top-logo {
        animation: bg-scale-smaller .5s ease;
        animation-fill-mode: forwards;
    }

    @keyframes bg-scale-bigger {
        0% {
            height: 3rem;
        }
        100% {
            height: 4rem;
        }
    }

    @keyframes bg-scale-smaller {
        0% {
            height: 4rem;
        }
        100% {
            height: 3rem;
        }
    }

    .dropdown {
        height: 100%;
    }

    .dropdown-toggle {
        height: 100%;
    }

    .dropdown-menu {
        background: #250f16;
    }

    .dropdown-item:hover {
        background: #41081b;
        color: #FFDDBA;
    }

    .dropdown-menu {
        background: #250f16;
    }

    .dropdown-item {
        color: #d68637;
    }
}